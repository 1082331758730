import { AuthLayout, BorderBox, BorderButton, Form, StudentPasswordInput, StudentTextInput } from '@app/components'
import { getAuthServerSideProps } from '@app/lib/server'
import { Alert, Anchor, Box, Grid, Group, Text, Title } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { type NextPage } from 'next'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
// @ts-ignore
import { type SignInErrorTypes } from 'next-auth/core/pages/signin'
import { signIn } from 'next-auth/react'
import { event as trackEvent } from 'nextjs-google-analytics'
import React, { useCallback, useState } from 'react'
import { z } from 'zod'

import graduateBrain from '/public/graduateBrain.png'

const errors: Record<SignInErrorTypes, string> = {
  Signin: 'Try logging in with a different account',
  OAuthSignin: 'Try logging in with a different account',
  OAuthCallback: 'Try logging in with a different account',
  OAuthCreateAccount: 'Try logging in with a different account',
  EmailCreateAccount: 'Try logging in with a different account',
  Callback: 'Try logging in with a different account',
  OAuthAccountNotLinked: 'To confirm your identity, log in with the same account you used originally',
  EmailSignin: 'The email could not be sent',
  CredentialsSignin: 'Incorrect email or password',
  SessionRequired: 'Please log in to access this page',
  default: 'Unable to login'
}
const schema = z.object({
  email: z.string().trim().email(),
  password: z.string().trim()
})

interface LoginPageQueryParams {
  callbackUrl?: string
  error?: SignInErrorTypes
}

const defaultCallbackUrl = '/dashboard'

const LoginPage: NextPage = () => {
  const router = useRouter()
  const { callbackUrl = defaultCallbackUrl }: LoginPageQueryParams = router.query
  const [error, setError] = useState<null | string>(null)
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const form = useForm({
    initialValues: {
      email: '',
      password: ''
    },
    validate: zodResolver(schema),
    validateInputOnChange: false
  })

  return (
    <AuthLayout title='Login'>
      <Grid gutter={60}>
        <Grid.Col
          sm={12}
          md={6}
        >
          <Title
            size={42}
            mb='lg'
          >
            Login
          </Title>
          <BorderBox>
            <Form
              buttons={
                <Group
                  mb='lg'
                  grow
                  w='100%'
                >
                  <BorderButton
                    disabled={isLoggingIn}
                    color='#ECECEC'
                    style={{
                      paddingLeft: '1rem',
                      paddingRight: '1rem'
                    }}
                    fullWidth
                    type='submit'
                  >
                    Login
                  </BorderButton>
                  {/*@todo - If a user inputs their email address and subsequently clicks 'Create Account,' the entered email should persist on the page to which they are redirected.*/}
                  <Anchor
                    component={Link}
                    href='/register'
                  >
                    <BorderButton
                      style={{
                        paddingLeft: '1rem',
                        paddingRight: '1rem'
                      }}
                      fullWidth
                    >
                      Create Account
                    </BorderButton>
                  </Anchor>
                </Group>
              }
              isSaving={isLoggingIn}
              isValid={form.isValid()}
              onSubmit={form.onSubmit(
                useCallback(
                  async (values) => {
                    setIsLoggingIn(true)

                    const response = await signIn('credentials', {
                      ...values,
                      callbackUrl,
                      redirect: false
                    })

                    if (!response?.ok) {
                      if (response?.error) {
                        setError(errors[response.error as SignInErrorTypes] ?? errors.default)
                      }

                      setIsLoggingIn(false)
                    } else {
                      trackEvent('login')
                      await router.push(response?.url || defaultCallbackUrl)
                    }
                  },
                  [callbackUrl, router]
                )
              )}
            >
              <StudentTextInput
                mb='lg'
                withAsterisk
                label='Email'
                placeholder='your@email.com'
                autoComplete='email'
                disabled={isLoggingIn}
                error={form.errors.email}
                icon={null}
                onChange={(event) => {
                  setError(null)
                  form.setFieldValue('email', event.currentTarget.value)
                }}
                required
                type='email'
                value={form.values.email}
              />

              <StudentPasswordInput
                mb='lg'
                withAsterisk
                label='Password'
                autoComplete='current-password'
                disabled={isLoggingIn}
                error={form.errors.password}
                icon={null}
                onChange={(event) => {
                  setError(null)
                  form.setFieldValue('password', event.currentTarget.value)
                }}
                placeholder='password'
                required
                requirements={null}
                value={form.values.password}
              />
              {error && (
                <Alert
                  color='red'
                  mb='lg'
                  radius='sm'
                  variant='filled'
                >
                  {error}
                </Alert>
              )}
            </Form>

            <Text size='lg'>
              <Anchor
                component={Link}
                href='/forgotPassword'
              >
                Forgot your password?
              </Anchor>
            </Text>
          </BorderBox>
        </Grid.Col>
        <Grid.Col
          sm={12}
          md={6}
        >
          <Box
            px='xl'
            mx='xl'
          >
            <Title
              order={1}
              mb='lg'
            >
              {`Feel the surge of confidence as you approach exam day.`}
            </Title>
            <Image
              src={graduateBrain}
              width={500}
              alt='Cartoon brain with a diploma and graduation cap'
            />
          </Box>
        </Grid.Col>
      </Grid>
    </AuthLayout>
  )
}

export const getServerSideProps = getAuthServerSideProps({ requiresSession: false })

// noinspection JSUnusedGlobalSymbols
export default LoginPage
